<template>
  <v-col>
    <div style="height: 10em;display: flex;align-items: center;justify-content: center;margin-top:30px;margin-bottom:30px">
      <v-img
        src="/banner-controle-eventos2.png"
        height="160"
        contain
      />
    </div>
    <crud-form
      :titulo-pagina="tituloPagina"
      :is-editting="isEditting"
      :mostra-botao-fechar="false"
      :tamanho-form="'larguraLogin xs12 sm9 md6 lg2'"
      :fluid="false"
    >
      <v-container class="py-0">
        <v-row>
          <v-col
            v-if="alteraSenha"
            cols="12"
          >
            <span>{{ $vuetify.lang.t('$vuetify.LoginForm.favor_alterar_senha') }}</span>
          </v-col>
          <v-col
            v-if="esqueciSenha"
            cols="12"
          >
            <span>{{ $vuetify.lang.t('$vuetify.LoginForm.digite_seu_email') }}</span>
          </v-col>
          <v-col cols="12">
            <span>{{ $vuetify.lang.t('$vuetify.LoginForm.email') }}</span>
            <v-text-field
              v-model="objEditar.usuairon"
              :readonly="alteraSenha"
            />
          </v-col>
          <v-col
            v-if="!esqueciSenha"
            cols="12"
          >
            <span>{{ $vuetify.lang.t('$vuetify.LoginForm.senha') }}</span>
            <v-text-field
              v-model="objEditar.master"
              :append-icon="mostraSenha ? 'mdi-eye-off' : 'mdi-eye'"
              :type="mostraSenha ? 'text' : 'password'"
              name="input-10-2"
              class="input-group--focused"
              @click:append="mostraSenha = !mostraSenha"
            />
          </v-col>
          <v-col
            v-if="alteraSenha"
            cols="12"
          >
            <span>{{ $vuetify.lang.t('$vuetify.LoginForm.nova_senha') }}</span>
            <v-text-field
              v-model="novaSenha"
              :append-icon="mostraSenhaConfirmar ? 'mdi-eye-off' : 'mdi-eye'"
              :type="mostraSenhaConfirmar ? 'text' : 'password'"
              name="input-10-2"
              class="input-group--focused"
              @click:append="mostraSenhaConfirmar = !mostraSenhaConfirmar"
            />
          </v-col>
          <v-col
            v-if="alteraSenha"
            cols="12"
          >
            <span>{{ $vuetify.lang.t('$vuetify.LoginForm.confirmarSenha') }}</span>
            <v-text-field
              v-model="confirmarSenha"
              :append-icon="mostraSenhaConfirmar ? 'mdi-eye-off' : 'mdi-eye'"
              :type="mostraSenhaConfirmar ? 'text' : 'password'"
              name="input-10-2"
              class="input-group--focused"
              @click:append="mostraSenhaConfirmar = !mostraSenhaConfirmar"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-col
        slot="botoes"
        cols="12"
      >
        <v-card-actions>
          <v-btn
            v-if="1 === 2"
            color="info"
            @click.stop="abrirRegistre()"
          >
            <v-icon dark>
              mdi-account-plus-outline
            </v-icon>
            {{ $vuetify.lang.t('$vuetify.RegisterForm.registreSe') }}
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="!alteraSenha"
            :disabled="$store.state.app.loading"
            :loading="$store.state.app.loading"
            color="success"
            @click.stop="signin()"
          >
            {{ $vuetify.lang.t('$vuetify.LoginForm.entrar') }}
            <v-icon dark>
              mdi-login
            </v-icon>
          </v-btn>
          <v-btn
            v-if="mostraBotaoEsqueciSenha"
            :disabled="$store.state.app.loading"
            :loading="$store.state.app.loading"
            color="orange"
            @click.stop="forgotPassword()"
          >
            {{ labelBotaoEsqueciSenha }}
            <v-icon dark>
              mdi-login
            </v-icon>
          </v-btn>
          <v-btn
            v-if="alteraSenha"
            :disabled="$store.state.app.loading"
            :loading="$store.state.app.loading"
            color="success"
            @click.stop="changePassword()"
          >
            {{ $vuetify.lang.t('$vuetify.padrao_salvar') }}
            <v-icon dark>
              mdi-login
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-col>
    </crud-form>
  </v-col>
</template>

<script>
  import { mapMutations } from 'vuex'
  import AuthenticationService from '../services/AuthenticationService'
  import { USER_KEY } from '@/config/config'

  export default {
    data: () => ({
      tituloPagina: 'Login',
      objPadrao: {
        usuairon: '',
        master: null
      },
      objEditar: {
        usuairon: '',
        master: null
      },
      isEditting: false,
      mostraSenha: false,
      mostraSenhaConfirmar: false,
      mostraBotaoEsqueciSenha: false,
      esqueciSenha: false,
      alteraSenha: false,
      payload: null,
      confirmarSenha: null,
      novaSenha: null
    }),
    computed: {
      regras: function () {
        let regra = {
          required: (valor) => !!valor || this.mensagemCampoObrigatorio,
          min: (v) => v.length >= 8 || this.mensagemMinimoCaracteres
        }
        return regra
      },
      mensagemCampoObrigatorio () {
        return this.$t('padrao_campo_obrigatorio') || 'Não Deu Campo Obrigatório'
      },
      mensagemMinimoCaracteres () {
        return (
          this.$t('LoginForm.validacao_minimo_caracteres') ||
          'Não Deu Minimo Caracteres'
        )
      },
      labelBotaoEsqueciSenha () {
        if (this.esqueciSenha) {
          return this.$t('LoginForm.enviar')
        } else {
          return this.$t('LoginForm.esqueci_a_senha')
        }
      }
    },
    created () {
      this.isEditting = false
      if (this.$route.path === '/resetPassword') {
        this.payload = this.$store.state.user
        this.objEditar.usuairon = this.payload.email
        this.alteraSenha = true
      }
    },
    methods: {
      signin () {
        this.setLoading(true)
        AuthenticationService.signin({
          email: this.objEditar.usuairon,
          password: this.objEditar.master
        })
          .then((res) => {
            if (!res.data.changePasswordNextLogin) {
              this.$store.commit('setUser', res.data)
              this.$store.commit(
                'setIdEmpresaCliente',
                res.data.idEmpresaCliente
              )
              localStorage.setItem(USER_KEY, JSON.stringify(res.data))
              this.$router.push({ path: '/' })
            } else {
              this.payload = res.data
              this.alteraSenha = true
            }
          })
          .catch((err) => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t(err.response.data)
            })
            this.mostraBotaoEsqueciSenha = err.response.status === 401
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
      forgotPassword () {
        if (!this.esqueciSenha) {
          this.esqueciSenha = true
        } else {
          this.esqueciSenha = false
          this.mostraBotaoEsqueciSenha = false
          AuthenticationService.redefinirSenha({ email: this.objEditar.usuairon })
            .then(() => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoDescricao: this.$t('LoginForm.um_email_foi_enviado')
              })
            })
            .catch((err) => {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t(err.response.data)
              })
            })
        }
      },
      changePassword () {
        if (this.novaSenha && this.novaSenha !== this.confirmarSenha) {
          return this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('LoginForm.as_senhas_diferem')
          })
        }
        this.setLoading(true)
        AuthenticationService.changePassword({
          email: this.objEditar.usuairon,
          password: this.objEditar.master,
          newPassword: this.novaSenha
        })
          .then(() => {
            this.$store.commit('setUser', this.payload)
            this.$store.commit(
              'setIdEmpresaCliente',
              this.payload.idEmpresaCliente
            )
            localStorage.setItem(USER_KEY, JSON.stringify(this.payload))
            this.$router.push({ path: '/' })

            this.$root.$emit('mostra_dialogo_info', {
              dialogoDescricao: this.$t('LoginForm.senha_alterada_com_sucesso')
            })
          })
          .catch((err) => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t(err.response.data)
            })
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
      ...mapMutations('app', ['setLoading']),
      abrirRegistre () {
        this.$router.push('/register')
      }
    }
  }
</script>
<style>
.larguraLogin {
  width: 50%;
  min-width: 350px;
}
</style>